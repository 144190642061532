import React, {useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import '../styles/templates/pageDetailArtists.css'
import Control from "../components/control";
import ReactMarkdown from "react-markdown";
import {Helmet} from "react-helmet";
import '../styles/theme/global.css'

const PageDetailArtist = ({data, pageContext}) => {
    const artists = data.strapiPageArtists
    const {next, prev} = pageContext

    const [activeImgSrc, setActiveImgSrc] = useState(artists.Image.publicURL)


    const handleClick = (ImgSrc) => {
        setActiveImgSrc(ImgSrc)
    }

    return (
        <>
            <Helmet>
                <title>
                    Ebisu Records - {artists.ArtistTitle}
                </title>
                <meta property={'og:image'} content={`https://www.ebisurecords.fr${artists.ImageSeo.publicURL}`}/>
                <meta name={"description"} content={artists.DescriptionSeo}/>
                <meta property={"og:title"} content={artists.TitleSeo}/>
                <meta property={"og:type"} content={"website"}/>
                <meta property={'og:description'} content={artists.DescriptionSeo}/>
                <meta property={"og:url"} content={'https://www.ebisurecords.fr'}/>
                <meta property={"og:site_name"} content={"ebisurecords"}/>
                <meta property={"og:locale"} content={"fr_FR"}/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={artists.TitleSeo}/>
                <meta property="twitter:description" content={artists.DescriptionSeo}/>
                <meta property="twitter:image"
                      content={`https://www.ebisurecords.fr${artists.ImageSeo.publicURL}`}/>
            </Helmet>

            <Header data={data}/>
            <Layout className={'page-artist'}>
                <section className={'d-flex flex-column justify-content-between control-spacing'}>
                    <div
                        className={'d-flex container-artist flex-column-reverse flex-md-row justify-content-md-between'}>
                        <div
                            className={'container-information col-md-5 d-flex d-md-unset flex-column justify-content-between'}>
                            <span className={'categories-pageDetail'}>{artists.categorie}</span>
                            <img src={`${artists.ArtistLogo.publicURL}`} alt={'Artist logo'} className={'logo-artist'}/>
                            <ReactMarkdown>{artists.Descriptions}</ReactMarkdown>
                            <div className={'info-partenaires-artists'}>
                                <span>Members : </span>
                                {artists.Members}
                            </div>
                            <div className={'info-partenaires-artists'}>
                                <span>Label : </span>
                                {artists.Label}
                            </div>
                            <div className={'info-partenaires-artists'}>
                                <span>Discography : </span>
                                {artists.Discography}
                            </div>
                            <div className={'follow-us-artist'}>
                                <div className={'d-flex align-items-center container-follow-us'}>
                                    <span className={'mr-2'}>Follow Us : </span>
                                    {
                                        artists.FollowUs.map(reaseauSociaux => (
                                            <a href={`${reaseauSociaux.Link}`} target='_blank' className={'mr-2'}
                                               rel="noreferrer">
                                                <img className={'logo-reaseau-social'}
                                                     src={reaseauSociaux.socialMedia.publicURL}
                                                     alt={'Reaseau Social'}/>
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-6 container-image-artist d-flex flex-md-row flex-column'}>
                            <div className="image-artist">
                                <img src={activeImgSrc} alt='current artist'/>
                            </div>
                            <div className={"albums-image ml-md-4 d-flex flex-md-column"} id={"container-albums"}>
                                <img
                                    className={'album-cover mr-1 mr-md-0 mt-2 mt-md-0'}
                                    onClick={() => handleClick(`${artists.Image.publicURL}`)}
                                    src={`${artists.Image.publicURL}`}
                                    alt={'album cover'}
                                />
                                {
                                    artists.Albums.map((album) => (
                                        <img key={album.id}
                                             className={'album-cover mr-1 mr-md-0 mt-2 mt-md-0'}
                                             onClick={() => handleClick(`${album.AlbumCover.publicURL}`)}
                                             src={`${album.AlbumCover.publicURL}`}
                                             alt={'album cover'}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Control classNames={'controler-artist'} prev={prev} next={next} pathPrev={prev ? prev.slug : null}
                             pathNext={next ? next.slug : null} slug={artists.categorie}/>
                </section>
            </Layout>

        </>
    )
}


export default PageDetailArtist

export const ArtistQuery = graphql`
    query ArtistQuery($slug: String!) {
        strapiPageArtists (
            slug: { eq: $slug }
        ) {
            ArtistTitle
            Descriptions
            Discography
            categorie
            Label
            Members
            id
            slug
            Albums {
              id
              AlbumCover {
                  publicURL
              }
            }
            Image {
                publicURL
            }
            FollowUs {
                socialMedia {
                    publicURL
                }
                Link
                Nom
            }
            ArtistLogo {
                publicURL
            }
            DescriptionSeo
            ImageSeo {
              publicURL
            }
            TitleSeo
        }
        strapiHeader {
            id
            ItemsNav {
                Item
            }
            Logo {
                publicURL
            }
        }
        strapiThemeChooser {
            Themes {
                button
                slug
                id
                title
                imageChooser {
                    publicURL
                }
            }
        }
    }
`